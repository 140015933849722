
import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'
import * as firebaseui from 'firebaseui'

const firebaseConfig = {
  apiKey: 'AIzaSyBYykH3eogzrBgCRD5McVQEgYcoe5mBvoY',
  authDomain: 'chatbot-mvp-389818.firebaseapp.com',
  projectId: 'chatbot-mvp-389818'
}

firebase.initializeApp(firebaseConfig)
const auth = firebase.auth()
const ui = new firebaseui.auth.AuthUI(auth)

export { firebase, auth, ui }
