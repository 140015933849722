import React, { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useGet } from '../hooks/useGet'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadBox } from '../common/RadBox'
import { RadButton } from '../common/RadButton'
import { RadCards } from '../common/RadCards'
import { RadGrid } from '../common/RadGrid'
import { RadHeader } from '../common/RadHeader'
import { RadLink } from '../common/RadLink'
import { RadMultiselect } from '../common/RadMultiselect'
import { RadPagination } from '../common/RadPagination'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadTextFilter } from '../common/RadTextFilter'

export function AgentList ({ selected }) {
  const pageLength = 20
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [currentPageIndex, setCurrentPageIndex] = useState(searchParams.get('page') != null ? parseInt(searchParams.get('page')) : 1)
  const [filteringText, setFilteringText] = useState(searchParams.get('search') ?? '')
  const [searchText, setSearchText] = useState(searchParams.get('search') ?? '')
  const [tagIds, setTagIds] = useState(searchParams.get('tagIds') ?? '')
  const { data: agents, count } = useGet(`/api/agent?search=${searchText}&tagIds=${tagIds ?? ''}&limit=${pageLength}&offset=${(currentPageIndex - 1) * pageLength}`)
  const { data: tags } = useGet('/api/tag?limit=2000')

  if (
    agents != null &&
    tags != null
  ) {
    const tagOptions = tags.map((x) => { return { value: x.id.toString(), label: x.name } })

    return (
      <RadAppLayout
        contentHeader={
          <RadHeader
            variant='h1'
            actions={
              <RadSpaceBetween direction='horizontal' size='xs'>
                <RadButton onClick={() => navigate('/agent/create')}>Create</RadButton>
              </RadSpaceBetween>
            }
            counter={'(' + count + ')'}
          >
            Agents
          </RadHeader>
        }
        content={
          <RadCards
            cardDefinition={{
              header: item => <RadLink fontSize='heading-m' href={`/agent/${item.id}`}>{item.name}</RadLink>,
              sections: [
                {
                  id: 'type',
                  header: 'Type',
                  content: item => item.type.name
                },
                {
                  id: 'description',
                  header: 'Description',
                  content: item => item.description ?? '-'
                },
                {
                  id: 'tags',
                  header: 'Tags',
                  content: item => {
                    if (item.tags.length > 0) {
                      return item.tags.map((x) => x.tag.name).join(', ')
                    } else {
                      return '-'
                    }
                  }
                }
                // {
                //   id: 'invitationSentAt',
                //   header: 'Invitation Sent',
                //   content: item => item.invitationSentAt != null ? formatDateTime(item.invitationSentAt) : '-'
                // },
                // {
                //   id: 'percentComplete',
                //   content: item => {
                //     let percentComplete = -1
                //     let approximately = false
                //     if (item.lastMessageText != null) {
                //       const regex = /(\d+)% Complete with Interview/
                //       const match = item.lastMessageText.match(regex)
                //       percentComplete = match ? match[1] : -1
                //     }
                //     if (item.lastMessageText != null) {
                //       const regex = /\[Approximately (\d+)% Complete with Interview/
                //       const match = item.lastMessageText.match(regex)
                //       if (match) approximately = true
                //     }
                //     return (<RadBox fontWeight='bold'>{((approximately ? 'Approximately ' : '') + (percentComplete ?? '0') + '% Complete ').replace('-1% Complete', 'Not Started')}</RadBox>)
                //   }
                // }
              ]
            }}
            cardsPerRow={[
              { cards: 1 },
              { minWidth: 640, cards: 2 },
              { minWidth: 960, cards: 3 },
              { minWidth: 1280, cards: 4 }
            ]}
            items={agents}
            variant='full-page'
            filter={
              <RadGrid
                gridDefinition={[
                  { colspan: { default: 12, s: 8 } },
                  { colspan: { default: 12, s: 4 } }
                ]}
              >
                <RadTextFilter
                  filteringPlaceholder='Search'
                  filteringAriaLabel='Search agents'
                  filteringText={filteringText}
                  onChange={({ detail }) => setFilteringText(detail.filteringText)}
                  onDelayedChange={({ detail }) => {
                    setSearchText(detail.filteringText)
                    setCurrentPageIndex(1)
                    searchParams.set('page', 1)
                    if (detail.filteringText) {
                      searchParams.set('search', detail.filteringText)
                    } else {
                      searchParams.delete('search')
                    }
                    setSearchParams(searchParams)
                  }}
                />
                <RadMultiselect
                  filteringType='auto'
                  selectedOptions={tagOptions.filter((x) => tagIds.split('-').includes(x.value))}
                  onChange={({ detail }) => {
                    const ids = detail.selectedOptions.map((x) => x.value).join('-')
                    if (ids === '') {
                      searchParams.delete('tagIds')
                    } else {
                      searchParams.set('tagIds', ids)
                    }
                    setTagIds(ids)
                    searchParams.set('page', 1)
                    setCurrentPageIndex(1)
                    setSearchParams(searchParams)
                  }}
                  options={tagOptions}
                  selectedAriaLabel='Selected'
                  deselectAriaLabel={e => `Remove ${e.label}`}
                  placeholder='Choose tags'
                  keepOpen={false}
                />
              </RadGrid>
            }
            pagination={
              <RadPagination
                currentPageIndex={currentPageIndex}
                pagesCount={Math.ceil(count / pageLength)}
                onChange={({ detail }) => {
                  searchParams.set('page', detail.currentPageIndex)
                  setSearchParams(searchParams)
                  setCurrentPageIndex(detail.currentPageIndex)
                }}
                ariaLabels={{
                  nextPageLabel: 'Next page',
                  previousPageLabel: 'Previous page',
                  pageLabel: pageNumber => `Page ${pageNumber} of all pages`
                }}
              />
            }
            empty={
              <RadBox textAlign='center' color='inherit'>
                No matches found.
              </RadBox>
            }
          />
        }
      />
    )
  }
}
