import { useContext } from 'react'
import { AppContext } from './../App'
import { removeEmptyStrings } from '../common/utilities'

export function useStreamPost (uri, data, { onSuccess }) {
  const { user, setError, setNotFound, setPermissionDenied } = useContext(AppContext)

  async function post () {
    setError(null)
    const headers = {
      'Content-Type': 'application/json'
    }
    if (user != null) {
      const token = await user.getIdToken()
      headers.Authorization = `Bearer ${token}`
    }
    const response = await fetch(uri, {
      method: 'POST',
      headers,
      body: JSON.stringify(removeEmptyStrings(data))
    })
    if (response.status === 200) {
      try {
        const reader = response.body.getReader()
        const decoder = new TextDecoder()
        const chunks = []

        // IMPORTANT: we do not increment the reloadCounter
        setError(null)
        setNotFound(false)
        setPermissionDenied(null)

        while (true) {
          const { done, value } = await reader.read()
          if (done) { break }
          chunks.push(decoder.decode(value))
          onSuccess({ data, done: false, text: chunks.join('') })
        }
        onSuccess({ data, done: true, text: chunks.join('') })
      } catch (err) {
        console.error(err)
        setError({ code: 'Error', message: err.message })
      }
    } else if (response.status === 400) {
      try {
        const json = await response.json()
        setError({ ...json, code: 'Validation Error' })
      } catch {
        setError({ code: 'Validation Error' })
      }
    } else if (response.status === 403) {
      try {
        const text = await response.text()
        setError({ code: 'Permission Denied', message: text })
      } catch {
        setError({ code: 'Permission Denied', message: '' })
      }
    } else if (response.status === 404) {
      setError({ code: 'Not Found' })
    } else if (response.status === 500) {
      try {
        const json = await response.json()
        if (json.name === 'SequelizeForeignKeyConstraintError') {
          setError({ code: 'Unprocessable', message: 'Unable to delete. Resource is in use.' })
        } else if (json.name === 'SequelizeUniqueConstraintError') {
          setError({ code: 'Unprocessable', message: json?.errors[0]?.message ?? '' })
        } else {
          throw new Error('Unknown error') // to get into the `catch`
        }
      } catch {
        setError({ code: 'Error', message: 'Please contact administrator.' })
      }
    } else {
      setError({ code: response.statusText })
    }
  }

  return post
}
