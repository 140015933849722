import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useGet } from '../hooks/useGet'
import { useDelete } from '../hooks/useDelete'
import { useConfirm } from '../hooks/useConfirm'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadHeader } from '../common/RadHeader'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadContainer } from '../common/RadContainer'
import { RadColumnLayout } from '../common/RadColumnLayout'
import { RadBox } from '../common/RadBox'
import { RadLink } from '../common/RadLink'
import { RadButton } from '../common/RadButton'

export function UserDetail () {
  const navigate = useNavigate()
  const { userId } = useParams()
  const { data: user } = useGet(`/api/user/${userId}`)
  const confirmDelete = useConfirm('Delete user?', 'Delete user permanently? This action cannot be undone.', 'Delete', () => { remove() })
  const remove = useDelete(`/api/user/${userId}`, () => { navigate('/user') })

  function edit (evt) {
    evt.preventDefault()
    navigate('edit')
  }

  if (user) {
    return (
      <RadAppLayout
        name={user.name}
        contentHeader={
          <RadHeader
            variant='h1'
            description={user.email ? <RadLink href={`mailto:${user.email}`}>{user.email}</RadLink> : ''}
            actions={
              <RadSpaceBetween direction='horizontal' size='xs'>
                <RadButton onClick={edit}>Edit</RadButton>
                <RadButton onClick={confirmDelete}>Delete</RadButton>
              </RadSpaceBetween>
            }
          >
            {user.name}
          </RadHeader>
        }
        content={
          <RadSpaceBetween size='l'>
            <RadContainer
              header={
                <RadHeader
                  variant='h2'
                >
                  {user.photoUrl &&
                    <RadBox padding={{ bottom: 's' }}>
                      <img className='profile' src={user.photoUrl} alt={user.name} referrerPolicy='no-referrer' />
                    </RadBox>}
                  Details
                </RadHeader>
              }
            >
              <RadColumnLayout columns={3} borders='vertical'>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Title
                  </RadBox>
                  <div>{user.title ?? '-'}</div>
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Phone
                  </RadBox>
                  <div>{user.phone ?? '-'}</div>
                </div>
                <div>
                  <RadBox variant='awsui-key-label'>
                    Roles
                  </RadBox>
                  {user.roles.map((role) => {
                    return (
                      <div key={role.id}><RadLink href={`/role/${role.id}`}>{role.name}</RadLink></div>
                    )
                  })}
                </div>
              </RadColumnLayout>
            </RadContainer>
          </RadSpaceBetween>
        }
      />
    )
  }
}
