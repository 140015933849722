import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useGet } from '../hooks/useGet'
import { useDelete } from '../hooks/useDelete'
import { useConfirm } from '../hooks/useConfirm'
import { Avatar } from '@rmwc/avatar'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadBox } from '../common/RadBox'
import { RadButton } from '../common/RadButton'
import { RadColumnLayout } from '../common/RadColumnLayout'
import { RadContainer } from '../common/RadContainer'
import { RadExpandableSection } from '../common/RadExpandableSection'
import { RadHeader } from '../common/RadHeader'
import { RadLineBreakRenderer } from '../common/RadLineBreakRenderer'
import { RadLink } from '../common/RadLink'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { formatDate, formatDateTime } from '../common/utilities'

export function PersonDetail () {
  const navigate = useNavigate()
  const { personId } = useParams()
  const { data: person } = useGet(`/api/person/${personId}`, true)
  const confirmDelete = useConfirm('Delete person?', 'Delete person permanently? This action cannot be undone.', 'Delete', () => { remove() })
  const remove = useDelete(`/api/person/${personId}`, () => { navigate('/person') })

  if (person != null) {
    return (
      <RadAppLayout
        name={person.fullName}
        contentHeader={
          <RadHeader
            variant='h1'
            actions={
              <RadSpaceBetween direction='horizontal' size='xs'>
                <RadButton onClick={() => navigate('edit')}>Edit</RadButton>
                <RadButton onClick={confirmDelete} disabled={person.isProtected}>Delete</RadButton>
              </RadSpaceBetween>
            }
          >
            {person.fullName}
          </RadHeader>
        }
        content={
          <RadSpaceBetween size='l'>
            <RadContainer
              header={
                <RadHeader variant='h2'>
                  Details
                </RadHeader>
              }
            >
              <RadSpaceBetween size='l'>
                <RadColumnLayout columns={4} borders='vertical'>
                  <div>
                    <RadBox variant='awsui-key-label'>
                      Organization
                    </RadBox>
                    <div>{person.organization ?? '-'}</div>
                  </div>
                  <div>
                    <RadBox variant='awsui-key-label'>
                      Email
                    </RadBox>
                    <div>{person.email != null ? <RadLink href={'mailto: ' + person.email}>{person.email}</RadLink> : '-'}</div>
                  </div>
                </RadColumnLayout>
              </RadSpaceBetween>
            </RadContainer>
            {person.invitations.map(invitation => {
              const messages = person.messages.filter(message => message.invitationId === invitation.id)
              return (
                <RadExpandableSection
                  key={'invitation-' + invitation.id}
                  header={
                    <RadHeader
                      variant='h2'
                      counter={'(' + messages.length + ')'}
                    >
                      <RadLink fontSize='inherit' href={'/agent/' + invitation.agent.id}>{invitation.agent.name}</RadLink>
                    </RadHeader>
                  }
                  variant='container'
                >
                  <div className='messages'>
                    <RadSpaceBetween size='l'>
                      {messages
                        .map(message => {
                          return (
                            <div key={message.id} className={message.direction}>
                              {message.direction === 'in' &&
                                <Avatar
                                  src='../mio.png'
                                  size='large'
                                  name='MIO'
                                  referrerPolicy='no-referrer'
                                />}
                              {message.direction === 'out' &&
                                <Avatar
                                  size='large'
                                  name={`${person.firstName} ${person.lastName}`}
                                  referrerPolicy='no-referrer'
                                />}
                              <div className='message'><span><RadLineBreakRenderer text={message.text.replace(/\[(.*?)\]/g, '').trim()} /></span></div>
                              <div className='time'>{formatDateTime(message.createdAt).replace(formatDate(Date.now()), '')}</div>
                            </div>
                          )
                        }
                        )}
                    </RadSpaceBetween>
                  </div>
                  {person.messages.length === 0 &&
                    <RadBox textAlign='center' color='inherit' padding={{ bottom: 'l' }}>
                      No messages found.
                    </RadBox>}
                </RadExpandableSection>
              )
            })}
          </RadSpaceBetween>
        }
      />
    )
  }
}
