import React, { useState } from 'react'

export function RadShowMore ({ text, maxLength = 150 }) {
  const [showMore, setShowMore] = useState(false)
  const truncatedText = showMore ? text : text.slice(0, maxLength).trim() + (text.length > maxLength ? '...' : '')

  return (
    <div>
      {
        truncatedText.includes('\n')
          ? truncatedText.split('\n')
            .map((x, idx) => { return <React.Fragment key={idx}>{x}<br /></React.Fragment> })
          : truncatedText
      }
      {text.length > maxLength && !showMore && <button className='show-more' onClick={() => { setShowMore(!showMore) }}>more</button>}
      {text.length > maxLength && showMore && <button className='show-more' onClick={() => { setShowMore(!showMore) }}>less</button>}
    </div>
  )
}
