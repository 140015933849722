import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useGet } from '../hooks/useGet'
import { useStreamPost } from '../hooks/useStreamPost'
import { useDelete } from '../hooks/useDelete'
import { useConfirm } from '../hooks/useConfirm'
import { Avatar } from '@rmwc/avatar'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadBox } from '../common/RadBox'
import { RadButton } from '../common/RadButton'
import { RadContainer } from '../common/RadContainer'
import { RadHeader } from '../common/RadHeader'
import { RadLineBreakRenderer } from '../common/RadLineBreakRenderer'
import { RadProgressBar } from '../common/RadProgressBar'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { formatDate, formatDateTime } from '../common/utilities'
import './Invite.scss'

const today = formatDate(Date.now())
function getCreatedAt (createdAt = new Date().toISOString()) {
  // Only keep the date part if not today
  return formatDateTime(createdAt).replace(today, '').trim()
}

export function Invite () {
  const { uuid } = useParams()
  const { data: invitation } = useGet(`/api/invitation/${uuid}`, true)
  const { data: originalMessages } = useGet(`/api/message/${uuid}`)
  const [formValues, setFormValues] = useState({})
  const [percentComplete, setPercentComplete] = useState(0)
  // `initialized` keeps track of whether we've called `go()` for this conversation or not
  const [initialized, setInitialized] = useState(false)
  const [mioState, setMioState] = useState('idle') // 'idle' -> 'thinking' -> 'writing' -> 'idle'
  const [responses, setResponses] = useState([])
  const [messages, setMessages] = useState([])

  function addMessages (msgs) {
    const createdAt = getCreatedAt()
    const copy = messages.slice(0)
    for (const msg of msgs) {
      // Format `createdAt`
      msg.createdAt = msg.createdAt != null
        ? getCreatedAt(msg.createdAt)
        : createdAt

      // Detect metadata
      const newResponses = []
      for (const match of msg.text.matchAll(/\[([^[\]]+)\]?/g)) {
        // Detect progress tracker
        const percent = match[1].match(/^\s*PROGRESS\s*:?\s*(\d+)\s*%?\s*(?:Complete)?/i)
        if (percent != null) {
          const parsed = parseInt(percent[1])
          if (parsed > percentComplete) {
            setPercentComplete(parsed)
          }
          msg.text = msg.text.replace(match[0], '')
          continue
        }
        // Detect suggested responses
        const response = match[1].match(/^\s*RESPONSE\s*:?\s*(.+)$/i)
        if (response != null) {
          newResponses.push(response[1].trim())
          msg.text = msg.text.replace(match[0], '')
          continue
        }
      }
      setResponses(newResponses)
      msg.text = msg.text.trim()
      copy.push(msg)
    }
    setMessages(copy)
  }
  const go = useStreamPost(`/api/message/go/${uuid}`, {}, {
    onSuccess: ({ done, text }) => {
      setMioState(done ? 'idle' : 'writing')
      addMessages([{ text, incomplete: !done, direction: 'in' }])
    }
  })
  const post = useStreamPost(`/api/message/${uuid}`, formValues, {
    onSuccess: ({ data, done, text }) => {
      setMioState(done ? 'idle' : 'writing')
      addMessages([{ text: data.text, direction: 'out' }, { text, incomplete: !done, direction: 'in' }])
    }
  })
  const remove = useDelete(
    `/api/message/${uuid}`,
    () => {
      setPercentComplete(0)
      setResponses([])
      setMessages([])
      setInitialized(false)
      setMioState('idle')
    })
  const confirmDelete = useConfirm('Start over?', 'Start over? This action cannot be undone.', 'Start Over', () => { remove() })

  useEffect(() => {
    if (originalMessages == null) { return }
    if (originalMessages.length === 0 && !initialized) {
      setInitialized(true)
      setMioState('thinking')
      addMessages([{ text: 'Initializing MIO. Be right with you...', direction: 'in' }])
      go()
    } else if (originalMessages.length > 0) {
      setInitialized(true)
      addMessages(originalMessages)
    }
    // eslint-disable-next-line
  }, [originalMessages])

  useEffect(() => {
    if (formValues.text != null && formValues.text.trim() !== '') {
      setMioState('thinking')
      addMessages([{ direction: 'out', text: formValues.text }])
      post()
    }
    // eslint-disable-next-line
  }, [formValues])

  useEffect(() => {
    document.documentElement.scrollTo({ top: document.documentElement.scrollHeight, behavior: 'smooth' })
  }, [messages])

  if (invitation != null) {
    return (
      <RadAppLayout
        breadcrumbs=' '
        navigationHide
        contentHeader={
          <RadHeader
            variant='h1'
            actions={
              <RadSpaceBetween direction='horizontal' size='xs'>
                <RadButton
                  onClick={confirmDelete}
                  disabled={messages.length === 0 || mioState !== 'idle'}
                >
                  Start Over
                </RadButton>
              </RadSpaceBetween>
            }
          >
            {invitation.agent.name}
          </RadHeader>
        }
        content={
          <div className='chat'>
            <RadContainer
              fitHeight
            >
              <div className='messages'>
                <RadSpaceBetween size='l'>
                  {messages.map((message, i) => {
                    return (
                      <div key={i} className={message.direction}>
                        {message.direction === 'in' &&
                          <Avatar
                            src='../mio.png'
                            size='large'
                            name='MIO'
                            referrerPolicy='no-referrer'
                          />}
                        {message.direction === 'out' &&
                          <Avatar
                            size='large'
                            name={`${invitation.person.firstName} ${invitation.person.lastName}`}
                            referrerPolicy='no-referrer'
                          />}
                        <div className='message'><span><RadLineBreakRenderer text={`${message.text}${message.incomplete ? ' …' : ''}`} /></span></div>
                        <div className='time'>{message.createdAt}</div>
                      </div>
                    )
                  })}
                  {mioState === 'thinking' &&
                    <div className='in'>
                      <Avatar
                        src='../mio.png'
                        size='large'
                        name='MIO'
                        referrerPolicy='no-referrer'
                      />
                      <div className='typing'>
                        <div className='typing__dot' />
                        <div className='typing__dot' />
                        <div className='typing__dot' />
                      </div>
                    </div>}
                  {invitation.agent.showProgressBar &&
                    <RadBox padding={{ left: 'xxxl', top: 'l' }} margin={{ left: 's' }}>
                      <RadProgressBar
                        label={`${percentComplete}% Complete`}
                        value={percentComplete}
                      />
                    </RadBox>}
                </RadSpaceBetween>
              </div>
            </RadContainer>
            <Input
              isDisabled={['thinking', 'writing'].includes(mioState)}
              onEnter={({ detail }) => setFormValues({ ...formValues, text: detail.value })}
            />
            {responses.length > 0 &&
              <RadBox padding={{ top: 'xxs' }}>
                <div className='responses'>
                  <span>Suggestions: </span>
                  {responses.map((response, index) =>
                    <button
                      key={index}
                      onClick={() => {
                        setFormValues({ ...formValues, text: response })
                        setResponses([])
                      }}
                    >
                      {response}
                    </button>
                  )}
                </div>
              </RadBox>}
          </div>
        }
      />
    )
  }
}

function Input ({ initialValue, isDisabled, onEnter, ...rest }) {
  const [value, setValue] = useState(initialValue ?? '')
  return (
    <div className='input'>
      <textarea
        inputMode='text'
        type='text'
        placeholder='Enter message'
        {...rest}
        value={value}
        autoFocus
        onChange={(event) => {
          const value = event.target.value
          setValue(value)
          if (value.trim() === '') {
            setValue('')
            onEnter({ detail: { value: '' } })
          }
        }}
        onKeyDown={(evt) => {
          if (evt.key === 'Enter' && !evt.shiftKey) {
            if (isDisabled) { return evt.preventDefault() }
            onEnter({ detail: { value } })
            setValue('')
          }
        }}
      />
      <button
        className='material-symbols-outlined'
        disabled={isDisabled}
        onClick={(evt) => {
          if (value.trim() !== '') {
            setValue('')
            onEnter({ detail: { value } })
          }
        }}
      >
        {isDisabled ? 'hourglass' : 'send'}
      </button>
    </div>
  )
}
