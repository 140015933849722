
    import './styles.scoped.css';
    export default {
  "content": "awsui_content_vjswe_15z0x_149",
  "button": "awsui_button_vjswe_15z0x_153",
  "variant-normal": "awsui_variant-normal_vjswe_15z0x_204",
  "variant-icon": "awsui_variant-icon_vjswe_15z0x_247",
  "variant-modal-dismiss": "awsui_variant-modal-dismiss_vjswe_15z0x_247",
  "variant-flashbar-icon": "awsui_variant-flashbar-icon_vjswe_15z0x_247",
  "variant-inline-icon": "awsui_variant-inline-icon_vjswe_15z0x_268",
  "disabled": "awsui_disabled_vjswe_15z0x_289",
  "variant-primary": "awsui_variant-primary_vjswe_15z0x_296",
  "variant-link": "awsui_variant-link_vjswe_15z0x_388",
  "variant-inline-link": "awsui_variant-inline-link_vjswe_15z0x_664",
  "variant-breadcrumb-group": "awsui_variant-breadcrumb-group_vjswe_15z0x_944",
  "variant-menu-trigger": "awsui_variant-menu-trigger_vjswe_15z0x_1041",
  "button-no-text": "awsui_button-no-text_vjswe_15z0x_1133",
  "button-no-wrap": "awsui_button-no-wrap_vjswe_15z0x_1137",
  "full-width": "awsui_full-width_vjswe_15z0x_1140",
  "icon-left": "awsui_icon-left_vjswe_15z0x_1159",
  "icon-right": "awsui_icon-right_vjswe_15z0x_1164",
  "icon": "awsui_icon_vjswe_15z0x_1159",
  "link": "awsui_link_vjswe_15z0x_1183",
  "disabled-with-reason": "awsui_disabled-with-reason_vjswe_15z0x_1183"
};
  