import React from 'react'
import { RadBox } from '../common/RadBox'

export function InviteNotFound () {
  return (
    <RadBox textAlign='center' padding={{ top: 'xxl' }}>
      <RadBox
        padding={{ top: 'xxl', bottom: 'xxl' }}
        fontSize='display-l'
        color='text-body-secondary'
        fontWeight='bold'
      >
        Not found. Please contact administrator.
      </RadBox>
    </RadBox>
  )
}
