import React, { useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { usePost } from '../hooks/usePost'
import { usePut } from '../hooks/usePut'
import { useGet } from '../hooks/useGet'
import { RadAppLayout } from '../common/RadAppLayout'
import { RadAttributeEditor } from '../common/RadAttributeEditor'
import { RadAutosuggest } from '../common/RadAutosuggest'
import { RadButton } from '../common/RadButton'
import { RadCheckbox } from '../common/RadCheckbox'
import { RadContainer } from '../common/RadContainer'
import { RadForm } from '../common/RadForm'
import { RadFormField } from '../common/RadFormField'
import { RadGrid } from '../common/RadGrid'
import { RadHeader } from '../common/RadHeader'
import { RadInput } from '../common/RadInput'
import { RadSelect } from '../common/RadSelect'
import { RadSpaceBetween } from '../common/RadSpaceBetween'
import { RadTextarea } from '../common/RadTextarea'

export function AgentEdit () {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { agentId } = useParams()
  const sourceId = searchParams.get('sourceId')
  const [formValues, setFormValues] = useState()
  const { data: agent } = useGet(agentId || sourceId ? `/api/agent/${agentId || sourceId}` : null)
  const { data: modelOptions } = useGet('/api/option/type?entity=model&required=true')
  const { data: tags } = useGet('/api/tag')
  const { data: typeOptions } = useGet('/api/option/type?entity=agent-type&required=true')
  const fields = [
    '[AGENT NAME]',
    '[LINK]',
    '[PERSON FIRST NAME]',
    '[PERSON MIDDLE NAME]',
    '[PERSON LAST NAME]',
    '[PERSON FULL NAME]',
    '[PERSON ORGANIZATION]'
  ]

  useEffect(() => {
    const defaultFormValues = { topP: 1, frequencyPenalty: 1, presencePenalty: 1, showProgressBar: false, invitationSubject: '[AGENT NAME] invitation', invitationBody: 'Click <a href="[LINK]">here</a>', tags: [] }
    setFormValues(agent ?? defaultFormValues)
  }, [agent])

  const create = usePost('/api/agent', formValues, (resp) => { navigate(`/agent/${resp.id}`) })
  const update = usePut(`/api/agent/${agentId}`, formValues, (resp) => { navigate(`/agent/${agentId}`) })

  async function saveChanges () {
    if (agentId != null) { update() } else { create() }
  }

  async function cancel () {
    if (agentId != null) {
      navigate(`/agent/${agentId}`)
    } else {
      navigate('/agent')
    }
  }

  if (
    formValues != null &&
    modelOptions != null &&
    tags != null &&
    typeOptions != null
  ) {
    return (
      <RadAppLayout
        name={formValues.name}
        contentHeader={
          <RadHeader variant='h1'>
            {formValues.id != null ? 'Edit Agent' : 'New Agent'}
          </RadHeader>
        }
        content={
          <form onSubmit={e => e.preventDefault()}>
            <RadForm
              actions={
                <RadSpaceBetween direction='horizontal' size='xs'>
                  <RadButton variant='link' onClick={cancel}>Cancel</RadButton>
                  <RadButton formAction='submit' variant='primary' onClick={saveChanges}>Save Changes</RadButton>
                </RadSpaceBetween>
              }
            >
              <RadSpaceBetween size='l'>
                <RadContainer
                  header={
                    <RadHeader variant='h2'>
                      Details
                    </RadHeader>
                  }
                >
                  <RadSpaceBetween size='l'>
                    <RadFormField label='Name *' field='name'>
                      <RadInput
                        placeholder='Enter name'
                        ariaRequired
                        value={formValues.name}
                        onChange={({ detail }) => setFormValues({ ...formValues, name: detail.value })}
                      />
                    </RadFormField>
                    <RadFormField label='Description *' field='description'>
                      <RadTextarea
                        placeholder='Enter description'
                        ariaRequired
                        value={formValues.description}
                        onChange={({ detail }) => setFormValues({ ...formValues, description: detail.value })}
                      />
                    </RadFormField>
                    <RadFormField label='Type *' field='typeId'>
                      <RadSelect
                        selectedOption={formValues.typeId ? typeOptions.find(x => x.value === formValues.typeId?.toString()) : { value: '', label: '-' }}
                        onChange={({ detail }) => {
                          if (detail.selectedOption.value !== '') {
                            setFormValues({ ...formValues, typeId: parseInt(detail.selectedOption.value) })
                          } else {
                            setFormValues({ ...formValues, typeId: null })
                          }
                        }}
                        options={typeOptions}
                        selectedAriaLabel='Selected'
                        empty='No matches found'
                      />
                    </RadFormField>
                    <RadGrid
                      gridDefinition={[
                        { colspan: { default: 12, m: 2 } },
                        { colspan: { default: 12, m: 2 } },
                        { colspan: { default: 12, m: 2 } },
                        { colspan: { default: 12, m: 2 } }
                      ]}
                    >
                      <RadFormField label='Model *' field='model'>
                        <RadSelect
                          selectedOption={formValues.model ? modelOptions.find(x => x.label === formValues.model?.toString()) : { value: '', label: '-' }}
                          onChange={({ detail }) => {
                            if (detail.selectedOption.value !== '') {
                              setFormValues({ ...formValues, model: detail.selectedOption.label })
                            } else {
                              setFormValues({ ...formValues, model: null })
                            }
                          }}
                          options={modelOptions}
                          selectedAriaLabel='Selected'
                          empty='No matches found'
                        />
                      </RadFormField>
                      <RadFormField label='Temperature *' field='temperature'>
                        <RadInput
                          placeholder='Enter temperature'
                          value={formValues.temperature}
                          onChange={({ detail }) => setFormValues({ ...formValues, temperature: detail.value })}
                        />
                      </RadFormField>
                      <RadFormField label='Maximum length' field='maximumLength'>
                        <RadInput
                          type='maximumLength'
                          placeholder='Enter length'
                          value={formValues.maximumLength}
                          onChange={({ detail }) => setFormValues({ ...formValues, maximumLength: detail.value })}
                        />
                      </RadFormField>
                      <RadFormField label='Frequency penalty *' field='frequencyPenalty'>
                        <RadInput
                          placeholder='Enter frequency penalty'
                          value={formValues.frequencyPenalty}
                          onChange={({ detail }) => setFormValues({ ...formValues, frequencyPenalty: detail.value })}
                        />
                      </RadFormField>
                      {/* <RadFormField label='Top P *' field='topP'>
                        <RadInput
                          placeholder='Enter Top P *'
                          value={formValues.topP}
                          onChange={({ detail }) => setFormValues({ ...formValues, topP: detail.value })}
                        />
                      </RadFormField> */}
                    </RadGrid>
                    <RadGrid
                      gridDefinition={[
                        { colspan: { default: 12, m: 2 } },
                        { colspan: { default: 12, m: 2 } },
                        { colspan: { default: 12, m: 2 } }
                      ]}
                    >
                      <RadFormField label='Presence penalty *' field='presencePenalty'>
                        <RadInput
                          placeholder='Enter presence penalty *'
                          value={formValues.presencePenalty}
                          onChange={({ detail }) => setFormValues({ ...formValues, presencePenalty: detail.value })}
                        />
                      </RadFormField>
                      <RadFormField label='Stop' field='stop'>
                        <RadInput
                          onChange={({ detail }) => setFormValues({ ...formValues, stop: detail.value })}
                          value={formValues.stop}
                          placeholder='Enter stop'
                          ariaRequired
                        />
                      </RadFormField>
                      <RadFormField label='Show progress bar' field='showProgressBar'>
                        <RadCheckbox
                          checked={formValues.showProgressBar}
                          onChange={({ detail }) => {
                            setFormValues({ ...formValues, showProgressBar: detail.checked })
                          }}
                        />
                      </RadFormField>
                    </RadGrid>
                    <RadFormField label='System Message *' field='systemMessage'>
                      <RadTextarea
                        rows={24}
                        placeholder='Enter system message'
                        ariaRequired
                        value={formValues.systemMessage}
                        onChange={({ detail }) => setFormValues({ ...formValues, systemMessage: detail.value })}
                      />
                    </RadFormField>
                    <RadFormField label='Document' field='document'>
                      <RadTextarea
                        rows={24}
                        placeholder='Enter document'
                        ariaRequired
                        value={formValues.document}
                        onChange={({ detail }) => setFormValues({ ...formValues, document: detail.value })}
                      />
                    </RadFormField>
                  </RadSpaceBetween>
                </RadContainer>
                {typeOptions.find(x => x.value === formValues.typeId?.toString())?.label === 'Chatbot' &&
                  <RadContainer
                    header={
                      <RadHeader
                        variant='h2'
                        description={'Fields: ' + fields.join(', ')}
                      >
                        Invitation
                      </RadHeader>
                  }
                  >
                    <RadSpaceBetween size='l'>
                      <RadFormField label='Subject *' field='invitationSubject'>
                        <RadInput
                          onChange={({ detail }) => setFormValues({ ...formValues, invitationSubject: detail.value })}
                          value={formValues.invitationSubject}
                          placeholder='Enter subject'
                          rows={6}
                          ariaRequired
                        />
                      </RadFormField>
                      <RadFormField label='Body *' field='invitationBody'>
                        <RadTextarea
                          onChange={({ detail }) => setFormValues({ ...formValues, invitationBody: detail.value })}
                          value={formValues.invitationBody}
                          placeholder='Enter body'
                          rows={6}
                          ariaRequired
                        />
                      </RadFormField>
                    </RadSpaceBetween>
                  </RadContainer>}
                <RadContainer
                  header={
                    <RadHeader variant='h2'>Tags</RadHeader>
                  }
                >
                  <RadSpaceBetween size='l'>
                    <RadAttributeEditor
                      onAddButtonClick={() => {
                        const tags = formValues.tags
                        tags.push({ uuid: uuidv4(), tag: {} })
                        setFormValues({ ...formValues, tags })
                      }}
                      onRemoveButtonClick={({
                        detail: { itemIndex }
                      }) => {
                        const tags = [...formValues.tags]
                        tags.splice(itemIndex, 1)
                        setFormValues({ ...formValues, tags })
                      }}
                      items={formValues.tags}
                      addButtonText='Add new tag'
                      definition={[
                        {
                          label: 'Name',
                          control: item => {
                            const tagOptions = tags.map((x) => { return { id: x.id, value: x.name } })
                            const filteredOptions = (tagOptions ?? []).filter((x) => !formValues.tags.map((y) => y.name).includes(x.value))
                            return (
                              <RadFormField field={`tag.${item.id || item.uuid}.name`}>
                                <RadAutosuggest
                                  options={filteredOptions}
                                  value={item.tag.name ?? ''}
                                  enteredTextLabel={value => `Use: "${value}"`}
                                  placeholder='Enter tag'
                                  onChange={({ detail }) => {
                                    const tags = formValues.tags
                                    const tag = formValues.tags.find((x) => x.uuid === (item.uuid ?? '') || x.id === (item.id ?? ''))
                                    const id = tagOptions.find((x) => x.value === detail.value)?.id
                                    tag.id = null
                                    tag.tag.id = id
                                    tag.tag.name = detail.value
                                    setFormValues({ ...formValues, tags })
                                  }}
                                />
                              </RadFormField>
                            )
                          }
                        }
                      ]}
                      removeButtonText='Remove'
                      empty='No tags added to this agent.'
                    />
                  </RadSpaceBetween>
                </RadContainer>
              </RadSpaceBetween>
            </RadForm>
          </form>
        }
      />
    )
  }
}
